import React from 'react';
import SEO from '../components/seo';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../components/layout';

const ImpressumPage = () => {

  const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Homepage"}) {
                id
                title
                description
                contentful_id
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                },
                contentModule {
                    ... on Node {
                        id
                        __typename
                    }
                }
            }
        }
    `);

  const menus = data.contentfulLayout.menu;
  const contentModule = data.contentfulLayout.contentModule;

  return (
    <Layout menus={ menus }>
      <SEO title="Impressum" />

      <div className="container mx-auto px-5 mb-10" id="impressum">
        <div className="text-4xl my-16">Impressum</div>

        <p className="text-2xl text-primary">MyFlexHome</p>
        <p className="text-xl">Hotel-Abo statt Zweitwohnung</p>
        <p className="font-bold mt-12 mb-4">Kontakt:</p>
        <p>hello@myflexhome.de</p>

        <p className="font-bold mt-12 mb-4">Verantwortlich für dieses Angebot gemäß § 5 TMG / § 18 MStV:</p>
        <p>MyFlexHome GmbH<br/>
          Pfälzer-Wald-Straße 64<br/>
          81539 München
        </p>
        <p className="mt-6">Vertreten durch: Kilian Ricken</p>
        <p className="mt-6">Amtsgericht München HRB 268953</p>

      </div>
    </Layout>
  )};

export default ImpressumPage;
